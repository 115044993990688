<template>
  <div class="z-value">
       <div
                class="border-radius-0 border-0"
                style="padding:100px 0 10px 0; background: #F2F2F2;"
              >
                <div class="logo-wrapper text-center ">
                  <img :src="(this.getSiteProps('clubdashboard.logocolor')!=undefined)?'/'+this.getSiteProps('clubdashboard.logocolor'):'/' +this.getSiteProps('clubdashboard.logo')" v-if="'/' + this.getSiteProps('clubdashboard.logo')" />
                </div>
                <div class="login-main-wrapper member-forgotpw">
                  <div class="login-wrapper">
                    <div class="card-header">
                      <h3>Forgot Password</h3>
                    </div>
                    <div class="card card-body">
                      <b-overlay :show="showOverlay" no-wrap></b-overlay>
                      <div class="alert alert-success" v-show="accExists === 1">
                        Forgot password link sent to your registered email.
                      </div>
                      <div class="alert alert-danger" v-show="accExists === 0 && multipleAccountExists === 0">
                         We are unable to locate your account using the email address or membership number provided. Please contact our <a :href="'mailto:'+this.getSiteProps('general.email')">Member Services Team</a> for assistance.
                      </div>
                      <div class="alert alert-danger" v-show="accExists === 0 && multipleAccountExists === 1">
                        Multiple accounts registered with this Email. Please try again with Membership ID
                      </div>
                      <form v-if="formEnable">
                        <div class="form-group">
                          <label>Email or {{this.getSiteProps('general.admin-team') || 'Club'}} ID</label>
                          <div class="input-group mb-3">
                            <div class="input-group-append">
                              <div class="input-group-text">
                                <img src="/dist/img/e-mail.png" alt="email" />
                              </div>
                            </div>
                            <input
                              type="text"
                              v-model="email"
                              name="email"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="form-button">
                          <b-button
                            class="btn-primary btn-blue"
                            @click="sendResetEmail"
                            >Send Email
                          </b-button>
                        </div>
                      </form>
                      <div v-if="!formEnable">
                        <p>{{ messages }}</p>
                        <div class="form-button">
                          <b-button
                            class="btn-primary btn-blue"
                            @click="loginRedirect"
                            >Go to Login
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

<div class="clubfooter-content">
 <clubfooter/>
</div>

  </div>
</template>
<script>
import clubfooter from "./components/Footer.vue"
import axios from "axios";

export default {
  name: "clubforgotpassword",
  components : {clubfooter},
  data() {
    return {
      email: "",
      accExists: "",
      multipleAccountExists: "",
      formEnable: true,
      showOverlay: false,
    };
  },
  methods: {
    sendResetEmail() {
      this.showOverlay = true;
      axios
        .post(this.basePath + "api/club/forgot_password", {
          email: this.email,
          type: "club",
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.accExists = 1;
            this.formEnable = false;
          } else if (res.data.status == "error") {
            this.accExists = 0;
            this.multipleAccountExists = 0;
            if(res.data.errors.multiple != undefined){
              this.multipleAccountExists = 1;
            } 
          }
          this.showOverlay = false;
        })
        .catch((err) => {
          console.log("Error on forgot password");
        });
    },
    loginRedirect() {
      this.$router.push({ path: "/club/login" });
    },
  },
};
</script>
